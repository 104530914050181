import React from 'react'
import styled, { Box, css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const CustomTextBreak = styled.span`
  ${breakpoints({
    /* eslint-disable sort-keys */
    md: css`
      h1 {
        font-size: 7.1vw;
      }
    `,
    xxxl: css`
      h1 {
        font-size: 115px;
      }
    `,
    /* eslint-enable sort-keys */
  })}
`

const FirstPage: React.FC = () => {
  const { t } = useTranslation()

  const content = {
    pretitle: t('dna.first_super', 'fill copy key: dna.first_super'),
    title: t('dna.first_title', 'fill copy key: dna.first_title'),
    text: t('dna.first_body', 'fill copy key: dna.first_body'),
    links: [
      {
        link: t('dna.first_cta', 'fill copy key: dna.first_cta'),
        url: '/our-dna/ohpeneers',
        variant: 'all',
      },
    ],
  }

  return (
    <>
      <Page
        content={
          <PageWrapper>
            <WithSlideShowCheck
              mobileContent={[<Text isMobile {...content} />]}
            >
              <Box row>
                <Box col={0.6} />
                <Box col={0.4}>
                  <CustomTextBreak>
                    <Text {...content} />
                  </CustomTextBreak>
                </Box>
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
    </>
  )
}
export default FirstPage
